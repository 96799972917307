<template>
  <header
    class="nav-container"
    :class="[
      showItem ? 'get-nav' : null,
      hiddenOnScroll ? 'nav-hidden-on-scroll' : null,
    ]"
    :style="bgStyle"
  >
    <router-link to="/" exact v-if="color === 'white'">
      <img src="@/assets/HIGO Logo-17.png" alt id="logoHigo" />
    </router-link>
    <router-link to="/" exact v-else>
      <img src="@/assets/HIGO.png" alt id="logoHigo" />
    </router-link>

    <div class="menu-container" :style="bgStyle">
      <div class="menu-btn" @click="showMenu" :class="[show ? 'close' : null]">
        <div class="btn-line"></div>
        <div class="btn-line" style="width: 22px; margin-left: 6px"></div>
        <div class="btn-line"></div>
      </div>
    </div>

    <div
      class="nav-item"
      :class="[showItem ? 'showItem' : null]"
      :style="bgStyle"
      v-clickoutside="onClose"
    >
      <div
        v-if="innerWidth < 992"
        class="item"
        :class="[slideIn ? 'slideIn' : null]"
      >
        <router-link exact to="/" :style="style">Home</router-link>
      </div>
      <div class="item" :class="[slideIn ? 'slideIn' : null]">
        <a href="/annualreport2024" :style="style">Annual Report</a>
      </div>
      <div class="item" :class="[slideIn ? 'slideIn' : null]">
        <router-link exact to="/about-us" :style="style">{{
          lang === "id" ? "Tentang Kami" : "About Us"
        }}</router-link>
      </div>

      <div class="dropdown">
        <div
          class="dropbtn"
          :class="[
            $route.name === 'Wifi Advertising' ||
            $route.name === 'higospot' ||
            $route.name === 'Integrated Digital Agency'
              ? 'yellow-clr'
              : null,
          ]"
        >
          {{ lang === "id" ? "Servis Kami" : "Our Services" }}
          <span style="padding-right: 5px"></span>
          <i class="fa fa-angle-down"></i>
        </div>
        <div class="dropdown-content">
          <router-link exact to="/wifi-advertising"
            >Wifi Advertising</router-link
          >
          <router-link exact to="/higospot">Higospot</router-link>
          <router-link exact to="/integrated-digital-agency"
            >Integrated Digital Agency</router-link
          >
        </div>
      </div>
      <div class="item" :class="[slideIn ? 'slideIn' : null]">
        <router-link
          exact
          to="/case-study"
          :class="[
            $route.name === 'Kintaro Suhsi' ||
            $route.name === 'Devon' ||
            $route.name === '21 Factory' ||
            $route.name === 'Walking Drums' ||
            $route.name === 'La' ||
            $route.name === 'Abbott' ||
            $route.name === 'Home Credit'
              ? 'yellow-clr'
              : null,
          ]"
          :style="style"
          >{{ lang === "id" ? "Studi Kasus" : "Case Study" }}</router-link
        >
      </div>
      <div
        class="item"
        :class="[slideIn ? 'slideIn' : null]"
        @click.prevent="goToBlog"
      >
        <div
          id="blog"
          style="margin: 0 30px; font-family: 'Roboto', sans-serif"
          @click.prevent="goToBlog"
          :style="style"
        >
          Blog
        </div>
      </div>
      <div class="item" :class="[slideIn ? 'slideIn' : null]">
        <router-link exact to="/contact-us" :style="style">{{
          lang === "id" ? "Hubungi Kami" : "Contact Us"
        }}</router-link>
      </div>

      <div @click.prevent="changeLang" id="lang-select">
        <div
          id="lang-switch"
          :class="[
            lang === 'id' && countLang === 0
              ? ''
              : lang === 'en' && countLang === 0
              ? 'lang-switch-en'
              : lang === 'id'
              ? 'lang-id'
              : 'lang-en',
          ]"
        >
          <img
            v-if="lang === 'id'"
            class="lang-img"
            src="@/assets/indonesia.png"
            alt=""
          />

          <img
            v-else
            class="lang-img"
            src="@/assets/united-kingdom.png"
            alt=""
          />
        </div>
        <div
          id="lang-text"
          :style="{
            left: lang === 'id' ? '2rem' : 'unset',
            right: lang === 'id' ? 'unset' : '2rem',
          }"
        >
          <h4 v-if="lang === 'id'">Bahasa</h4>
          <h4 v-else>English</h4>
        </div>
      </div>
    </div>
    <router-view />
  </header>
</template>

<script>
export default {
  name: "Navbar-component",
  props: {
    color: String,
    hideOnScrollDown: Boolean,
  },
  data: function () {
    return {
      press: false,
      show: false,
      navShow: false,
      showItem: false,
      slideIn: false,
      innerWidth: 0,
      lang: "id",
      countLang: 0,
      scrollY: window.scrollY || window.pageYOffset || 0,
      hiddenOnScroll: false,
    };
  },
  watch: {
    innerWidth(newWidth, oldWidth) {
      if (oldWidth < 991 && newWidth > 992) {
        this.show = true;
        this.showMenu();
      }
    },
    lang() {
      this.show = false;
      this.navShow = false;
      this.showItem = false;
      this.slideIn = false;
    },
  },
  mounted() {
    const langParams = location.search.split("lang=")[1];
    const langSession = sessionStorage.getItem("usr_dis_lang");
    if (langParams) {
      sessionStorage.setItem("usr_dis_lang", langParams);
    } else if (!langSession) {
      sessionStorage.setItem("usr_dis_lang", "id");
    }
    this.lang = sessionStorage.getItem("usr_dis_lang");
    this.$emit("lang", this.lang);
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    style() {
      return this.color === "white" ? "color: white" : "color: #0245ab;";
    },
    bgStyle() {
      if (this.color !== "white") {
        return "background-image: none !important; background-color: white; -webkit-box-shadow: 0px 3px 9px -2px rgba(148, 148, 148, 1);  -moz-box-shadow: 0px 3px 9px -2px rgba(148, 148, 148, 1); box-shadow: 0px 3px 9px -2px rgba(148, 148, 148, 1);";
      } else {
        return "";
      }
    },
  },
  directives: {
    clickoutside: {
      bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
          // here I check that click was outside the el and his childrens
          if (!(el === event.target || el.contains(event.target))) {
            // and if it did, call method provided in attribute value
            vnode.context[binding.expression](event);
          }
        };
        document.body.addEventListener("click", el.clickOutsideEvent);
      },
      unbind: function (el) {
        document.body.removeEventListener("click", el.clickOutsideEvent);
      },
      stopProp(event) {
        event.stopPropagation();
      },
    },
  },
  methods: {
    changeLang() {
      this.countLang++;
      let lang = null;
      if (this.lang === "id") lang = "en";
      else lang = "id";
      sessionStorage.setItem("usr_dis_lang", lang);
      this.lang = sessionStorage.getItem("usr_dis_lang");
      this.$emit("lang", this.lang);
    },
    onClose() {
      if (this.press) {
        this.show = true;
        this.navShow = true;
        this.showItem = true;
        this.slideIn = true;
        this.press = false;
      } else {
        this.show = false;
        this.navShow = false;
        this.showItem = false;
        this.slideIn = false;
      }
    },
    onResize() {
      this.innerWidth = window.innerWidth;
    },
    onScroll(evt) {
      if (!this.hideOnScrollDown) return;

      const scrollY = window.scrollY || window.pageYOffset || 0;
      const lastScrollY = this.scrollY;
      if (scrollY <= 200 && !this.hiddenOnScroll) {
        this.hiddenOnScroll = false;
      } else if (scrollY > lastScrollY && !this.hiddenOnScroll) {
        this.hiddenOnScroll = true;
      } else if (lastScrollY > scrollY && this.hiddenOnScroll) {
        this.hiddenOnScroll = false;
      }
      this.scrollY = scrollY;
    },
    showMenu() {
      if (this.show) {
        this.press = false;
      } else {
        this.press = !this.press;
      }
      // this.show = !this.show
    },
    goToBlog() {
      location.replace(`http://blog.higo.id?lang=${this.lang}`);
    },
  },
  created() {
    this.countLang = 0;
    this.innerWidth = window.innerWidth;
    window.addEventListener("scroll", this.onScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>

<style>
.dropdown {
  float: left;
  overflow: hidden;
  cursor: pointer;
}

.dropdown .dropbtn {
  border: none;
  outline: none;
  color: white;
  padding: 14px 16px;
  background-color: inherit;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 8px;
}

.dropdown-content a {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.topnav a:hover,
.dropdown:hover .dropbtn {
  background-color: transparent;
  color: white;
}

.dropdown-content a:hover {
  color: black;
}

.dropdown:hover .dropdown-content {
  display: block;
}

@keyframes shows {
  0% {
    height: 0rem;
  }
  100% {
    height: 25rem;
  }
}

@keyframes goRight {
  from {
    right: unset;
    left: 3px;
  }
  to {
    left: 100%;
    transform: translateX(calc(-100% - 3px));
  }
}

@keyframes goLeft {
  from {
    left: unset;
    right: 3px;
  }
  to {
    right: 100%;
    transform: translateX(calc(100% + 3px));
  }
}

.nav-container {
  min-height: 50px;
  display: flex;
  justify-content: center;
  text-align: center;
  /* transition: all 0.5s ease-out; */
  position: fixed;
  background: url("~@/assets/Rectangle 203-min.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  z-index: 999999;
  border: none;
  transition: transform 0.5s ease-in-out;
}

.nav-hidden-on-scroll {
  transform: translateY(-100%);
}

.yellow-clr {
  color: #f9ca45 !important;
}

.nav-item a {
  text-decoration: none;
  font-size: 1rem;
  transition: 0.3s;
  font-family: "Roboto", sans-serif;
}

.nav-item a:hover,
.router-link-active,
#blog:hover {
  color: #f9ca45 !important;
}

.nav-item a:active,
#blog:active {
  color: #f9ca45 !important;
}

.notShow {
  display: none;
}

.nav-container .menu-container {
  display: flex;
  width: 100%;
  height: 57px;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  top: 0;
}

#logoHigo {
  position: absolute;
  top: 1vh;
  left: 4vw;
  width: 75px;
  z-index: 999999;
}

#blog:hover {
  cursor: pointer;
}

#lang-select {
  width: 6rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(222, 222, 222);
  border: 1px solid white;
  border-radius: 20px;
  padding: 0 3px;
  position: relative;
  cursor: pointer;
  transition: 0.3s ease-out;
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.3) !important;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.3) !important;
}

#lang-switch {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  z-index: 3;
  left: 3px;
}

.lang-switch-en {
  left: unset !important;
  right: 3px !important;
}

.lang-img {
  width: 1.5rem;
  height: 1.5rem;
}

#lang-text {
  position: absolute;
  transition: 0.3s ease-out;
  color: rgb(2, 69, 171);
}

.lang-id {
  left: unset !important;
  animation: goLeft forwards 0.3s;
  transition: 0.3s ease-out;
}

.lang-en {
  animation: goRight forwards 0.3s;
  transition: 0.3s ease-out;
}

@media (max-width: 991px) {
  #logoHigo {
    top: 20px;
  }

  .nav-container {
    height: 57px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* transition: all 0.5s ease-out; */
    position: fixed;
    width: 100%;
    z-index: 90;
  }

  .nav-item {
    transition: all 0.5s ease-out;
    display: none;
    padding-top: 4rem;
  }

  .nav-container {
    display: flex;
    justify-content: flex-end;
  }

  .menu-btn {
    display: none;

    z-index: 1000000;
    margin-right: 5%;
    cursor: pointer;
    transition: all 0.5s ease-out;
  }
  .menu-btn .btn-line {
    width: 28px;
    height: 3px;
    margin: 0 0 5px 0;
    background: #0245ab;
    transition: all 0.5s ease-out;
  }
  .menu-btn.close {
    transition: all 0.5s ease-out;
    transform: rotate(180deg);
  }
  .menu-btn.close .btn-line:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }
  .menu-btn.close .btn-line:nth-child(2) {
    opacity: 0;
  }
  .menu-btn.close .btn-line:nth-child(3) {
    transform: rotate(-45deg) translate(7px, -6px);
    width: 28px;
  }

  .nav-item .item {
    transform: translate3d(600px, 0, 0);
    transition: all 0.5s ease-out;
  }

  .nav-item.showItem .slideIn {
    transform: translate3d(0, 0, 0);
  }

  .nav-item.showItem .item:nth-child(1) {
    transition-delay: 0.1s;
  }

  .nav-item.showItem .item:nth-child(2) {
    transition-delay: 0.2s;
  }
  .nav-item.showItem .item:nth-child(3) {
    transition-delay: 0.3s;
  }
  .nav-item.showItem .item:nth-child(4) {
    transition-delay: 0.4s;
  }
  .nav-item.showItem .item:nth-child(5) {
    transition-delay: 0.5s;
  }

  #lang-select {
    display: flex !important;
    align-items: center !important;
  }

  .lang-id {
    left: 3px !important;
    right: unset !important;
    animation: unset;
  }

  .lang-en {
    left: unset !important;
    right: 3px !important;
    animation: unset;
  }
  /* #logoHigo {
    position: absolute;
    top: 26px;
    left: 26px;
    width: 60px;
  } */
}

@media screen and (max-width: 991px) {
  .menu-btn {
    transition: all 0.5s ease-out;
    display: block;
  }

  .nav-container {
    background: unset;
  }

  .nav-container.get-nav {
    top: 0;
    width: 100vw;
    height: 100vh !important;
    z-index: 9999999;
    display: flex;
    flex-direction: column;
    /* transition: all 0.5s ease-out; */
    justify-content: flex-start !important;
    overflow: hidden;
    background-color: rgba(255, 255, 255, 0.3) !important;
  }

  .get-nav .showItem {
    width: 100%;
    animation: shows 0.5s forwards;
    z-index: 80;
    display: flex !important;
    flex-direction: column;
    transition: all 0.5s ease-out;
    justify-content: space-evenly;
    align-items: center;
    -webkit-box-shadow: 0px 3px 9px -2px rgba(148, 148, 148, 1);
    -moz-box-shadow: 0px 3px 9px -2px rgba(148, 148, 148, 1);
    box-shadow: 0px 3px 9px -2px rgba(148, 148, 148, 1);
    background: rgb(5, 82, 201);
    background: -moz-linear-gradient(
      90deg,
      rgba(5, 82, 201, 0.9900911048012955) 0%,
      rgba(1, 205, 232, 1) 100%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(5, 82, 201, 0.9900911048012955) 0%,
      rgba(1, 205, 232, 1) 100%
    );
    background: linear-gradient(
      90deg,
      rgba(5, 82, 201, 0.9900911048012955) 0%,
      rgba(1, 205, 232, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0552c9",endColorstr="#01cde8",GradientType=1);
    border-radius: 0 0 50px 50px;
    position: relative;
  }

  .menu-container {
    background: rgb(5, 82, 201);
    background: -moz-linear-gradient(
      90deg,
      rgba(5, 82, 201, 0.9900911048012955) 0%,
      rgba(1, 205, 232, 1) 100%
    );
    background: -webkit-linear-gradient(
      90deg,
      rgba(5, 82, 201, 0.9900911048012955) 0%,
      rgba(1, 205, 232, 1) 100%
    );
    background: linear-gradient(
      90deg,
      rgba(5, 82, 201, 0.9900911048012955) 0%,
      rgba(1, 205, 232, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0552c9",endColorstr="#01cde8",GradientType=1);
  }
}

@media screen and (min-width: 992px) {
  .nav-item {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 5%;
  }

  #logoHigo {
    top: 15.5px;
  }

  .menu-container {
    display: none !important;
  }

  .nav-container.get-nav {
    min-height: 50px;
    display: flex;
    justify-content: center;
    text-align: center;
    /* transition: all 0.5s ease-out; */
    position: fixed;
    background: url("~@/assets/Rectangle 203-min.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    z-index: 100;
    border: none;
  }

  .nav-item .item {
    width: 96px;
    margin: 0 10px;
  }
}

@media screen and (min-width: 1200px) {
  .nav-item {
    width: 100%;
    padding: 0 3rem 0 0;
    justify-content: flex-end;
  }

  .nav-item a {
    font-size: 1rem;
  }

  .nav-item .item {
    width: 128px;
  }
}
</style>
