var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"nav-container",class:[
    _vm.showItem ? 'get-nav' : null,
    _vm.hiddenOnScroll ? 'nav-hidden-on-scroll' : null,
  ],style:(_vm.bgStyle)},[(_vm.color === 'white')?_c('router-link',{attrs:{"to":"/","exact":""}},[_c('img',{attrs:{"src":require("@/assets/HIGO Logo-17.png"),"alt":"","id":"logoHigo"}})]):_c('router-link',{attrs:{"to":"/","exact":""}},[_c('img',{attrs:{"src":require("@/assets/HIGO.png"),"alt":"","id":"logoHigo"}})]),_c('div',{staticClass:"menu-container",style:(_vm.bgStyle)},[_c('div',{staticClass:"menu-btn",class:[_vm.show ? 'close' : null],on:{"click":_vm.showMenu}},[_c('div',{staticClass:"btn-line"}),_c('div',{staticClass:"btn-line",staticStyle:{"width":"22px","margin-left":"6px"}}),_c('div',{staticClass:"btn-line"})])]),_c('div',{directives:[{name:"clickoutside",rawName:"v-clickoutside",value:(_vm.onClose),expression:"onClose"}],staticClass:"nav-item",class:[_vm.showItem ? 'showItem' : null],style:(_vm.bgStyle)},[(_vm.innerWidth < 992)?_c('div',{staticClass:"item",class:[_vm.slideIn ? 'slideIn' : null]},[_c('router-link',{style:(_vm.style),attrs:{"exact":"","to":"/"}},[_vm._v("Home")])],1):_vm._e(),_c('div',{staticClass:"item",class:[_vm.slideIn ? 'slideIn' : null]},[_c('a',{style:(_vm.style),attrs:{"href":"/annualreport2024"}},[_vm._v("Annual Report")])]),_c('div',{staticClass:"item",class:[_vm.slideIn ? 'slideIn' : null]},[_c('router-link',{style:(_vm.style),attrs:{"exact":"","to":"/about-us"}},[_vm._v(_vm._s(_vm.lang === "id" ? "Tentang Kami" : "About Us"))])],1),_c('div',{staticClass:"dropdown"},[_c('div',{staticClass:"dropbtn",class:[
          _vm.$route.name === 'Wifi Advertising' ||
          _vm.$route.name === 'higospot' ||
          _vm.$route.name === 'Integrated Digital Agency'
            ? 'yellow-clr'
            : null,
        ]},[_vm._v("\n        "+_vm._s(_vm.lang === "id" ? "Servis Kami" : "Our Services")+"\n        "),_c('span',{staticStyle:{"padding-right":"5px"}}),_c('i',{staticClass:"fa fa-angle-down"})]),_c('div',{staticClass:"dropdown-content"},[_c('router-link',{attrs:{"exact":"","to":"/wifi-advertising"}},[_vm._v("Wifi Advertising")]),_c('router-link',{attrs:{"exact":"","to":"/higospot"}},[_vm._v("Higospot")]),_c('router-link',{attrs:{"exact":"","to":"/integrated-digital-agency"}},[_vm._v("Integrated Digital Agency")])],1)]),_c('div',{staticClass:"item",class:[_vm.slideIn ? 'slideIn' : null]},[_c('router-link',{class:[
          _vm.$route.name === 'Kintaro Suhsi' ||
          _vm.$route.name === 'Devon' ||
          _vm.$route.name === '21 Factory' ||
          _vm.$route.name === 'Walking Drums' ||
          _vm.$route.name === 'La' ||
          _vm.$route.name === 'Abbott' ||
          _vm.$route.name === 'Home Credit'
            ? 'yellow-clr'
            : null,
        ],style:(_vm.style),attrs:{"exact":"","to":"/case-study"}},[_vm._v(_vm._s(_vm.lang === "id" ? "Studi Kasus" : "Case Study"))])],1),_c('div',{staticClass:"item",class:[_vm.slideIn ? 'slideIn' : null],on:{"click":function($event){$event.preventDefault();return _vm.goToBlog.apply(null, arguments)}}},[_c('div',{staticStyle:{"margin":"0 30px","font-family":"'Roboto', sans-serif"},style:(_vm.style),attrs:{"id":"blog"},on:{"click":function($event){$event.preventDefault();return _vm.goToBlog.apply(null, arguments)}}},[_vm._v("\n        Blog\n      ")])]),_c('div',{staticClass:"item",class:[_vm.slideIn ? 'slideIn' : null]},[_c('router-link',{style:(_vm.style),attrs:{"exact":"","to":"/contact-us"}},[_vm._v(_vm._s(_vm.lang === "id" ? "Hubungi Kami" : "Contact Us"))])],1),_c('div',{attrs:{"id":"lang-select"},on:{"click":function($event){$event.preventDefault();return _vm.changeLang.apply(null, arguments)}}},[_c('div',{class:[
          _vm.lang === 'id' && _vm.countLang === 0
            ? ''
            : _vm.lang === 'en' && _vm.countLang === 0
            ? 'lang-switch-en'
            : _vm.lang === 'id'
            ? 'lang-id'
            : 'lang-en',
        ],attrs:{"id":"lang-switch"}},[(_vm.lang === 'id')?_c('img',{staticClass:"lang-img",attrs:{"src":require("@/assets/indonesia.png"),"alt":""}}):_c('img',{staticClass:"lang-img",attrs:{"src":require("@/assets/united-kingdom.png"),"alt":""}})]),_c('div',{style:({
          left: _vm.lang === 'id' ? '2rem' : 'unset',
          right: _vm.lang === 'id' ? 'unset' : '2rem',
        }),attrs:{"id":"lang-text"}},[(_vm.lang === 'id')?_c('h4',[_vm._v("Bahasa")]):_c('h4',[_vm._v("English")])])])]),_c('router-view')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }